/*
 *  Document   : theme-vip.scss
 *  Author     : RedStar Template
 *  Description: This scss file for white theme style classes
 */
@import "_theme-color-variables";

.theme-vip {
  .sidebar .menu .list li .menu-top:hover{
    background-color: #cccccc !important;
  }
  ng-image-slider .ng-image-slider-container .main {
    .next,
    .prev {
      background-color: $theme-vip-active-color !important;
      color: white !important;
    }
  }

  .profile-image-initial,
  .profile-image-initial-sm {
    background: $theme-vip-active-color;
    color: #fff;
  }
  .steps .step.completed .step-icon-wrap::before,
  .steps .step.completed .step-icon-wrap::after {
    background-color: $theme-vip-active-color !important;
  }

  .steps .step.completed .step-icon {
    border-color: $theme-vip-active-color !important;
    background-color: $theme-vip-active-color !important;
    color: #fff !important;
  }
  .text-theme {
    color: $theme-vip-active-color !important;
  }
  input:checked + .slider {
    background-color: $theme-vip-active-color !important;
  }

  input:focus + .slider {
    box-shadow: 0 0 1px $theme-vip-active-color !important;
  }
  .fc.fc-theme-standard {
    .fc-view-harness th {
      background: $theme-vip;
      border-color: rgb(221, 221, 221) smoke;
      color: $theme-vip-bar;
      a {
        color: $theme-vip-bar;
      }
    }

    .fc-view-harness .fc-event.fc-daygrid-block-event {
      color: $theme-vip;
      background: $theme-vip-bar;
      border-color: $theme-vip;
    }
    .fc-day-today .fc-daygrid-day-number {
      background: $theme-vip-bar;
      color: $theme-vip;
    }
  }
  .router-link-active {
    color: $theme-vip-active-color !important;
  }
  .nav-link::before {
    background-color: $theme-vip-active-color !important;
  }

  .navbar {
    background-color: transparent;
  }
  .navbar.active {
    background-color: $theme-vip !important;
    box-shadow: 0 1px 5px rgba(0, 0, 0, 0.25);
    .nav {
      > li {
        > a {
          color: $theme-vip-navbar-brand;
        }
      }
    }
    .collapse-menu-icon .mat-icon {
      color: $theme-vip-navbar-brand;
    }
    .nav-notification-icons .mat-icon,
    .navbar-toggle {
      color: $theme-vip-navbar-brand;
    }
  }

  .navbar-brand {
    color: $theme-vip-navbar-brand;

    &:hover {
      color: $theme-vip-navbar-brand_hover;
    }

    &:active {
      color: $theme-vip-navbar-brand_active;
    }

    &:focus {
      color: $theme-vip-navbar-brand_focus;
    }
  }

  .nav {
    > li {
      > a {
        &:hover {
          background-color: $theme-vip-nav-anchor_hover;
          text-decoration: none;
        }

        &:focus {
          background-color: $theme-vip-nav-anchor_focus;
          text-decoration: none;
        }
      }
    }

    .open {
      > a {
        background-color: $theme-vip-nav-anchor-opened;

        &:hover {
          background-color: $theme-vip-nav-anchor-opened_hover;
        }

        &:focus {
          background-color: $theme-vip-nav-anchor-opened_focus;
        }
      }
    }
  }

  .bars {
    color: $theme-vip-bar;
  }

  .sidebar {
    .menu {
      .list {
        li {
          &.active {
            background-color: $theme-vip-menu-list-active;

            > :first-child {
              i,
              span {
                color: $side-item-active;
              }
            }
          }
          a {
            -moz-transition: all 0.3s;
            -o-transition: all 0.3s;
            -webkit-transition: all 0.3s;
            transition: all 0.3s;
            i,
            span {
              -moz-transition: all 0.3s;
              -o-transition: all 0.3s;
              -webkit-transition: all 0.3s;
              transition: all 0.3s;
            }
            &:hover {
              color: $side-item-active;
              i,
              span {
                color: $side-item-active;
              }
            }
          }
        }

        .toggled::after {
          color: $side-item-active;
        }

        .ml-menu {
          background-color: $theme-vip-menu-list-submenu;
          .active {
            a {
              font-weight: 700;
            }
          }
          li.active a:not(.menu-toggle):before {
            content: "";
            display: block;
            width: 7px;
            height: 7px;
            border-radius: 50%;
            position: absolute;
            background-color: $theme-vip-active-color;
            border-color: $theme-vip-active-color;
            transform: translate(-10px, -50%);
            opacity: 1;
            transition: all 0.2s ease;
            color: #000;
          }
        }
      }
      .menu-toggle.toggled:after {
        color: $side-item-active;
      }
    }

    .legal {
      background-color: $theme-vip-legal-bg;

      .copyright {
        a {
          color: $theme-vip !important;
        }
      }
    }
  }

  .breadcrumb li a {
    color: $theme-vip-active-color !important;
  }

  .card .header h2 strong {
    color: $theme-vip-active-color !important;
  }

  .page-item.active .page-link {
    background-color: $theme-vip-active-color;
    border-color: $theme-vip-active-color;
    border-radius: 50%;
    margin: 5px;
    box-shadow:
      0 4px 5px 0 #d4d8da,
      0 1px 10px 0 #d4d8da,
      0 2px 4px -1px #d4d8da;
    padding: 0px 12px;
    min-width: 30px;
    line-height: 30px;
    color: #ffffff;
    text-transform: uppercase;
  }

  .btn-primary {
    background-color: $theme-vip-active-color !important;
    color: #fff !important;
    border-color: $theme-vip-active-color !important;
    &:hover {
      background-color: $theme-vip-active-color !important;
      color: #fff !important;
    }
    &:active {
      background-color: $theme-vip-active-color !important;
      color: #fff !important;
    }
    &:focus {
      background-color: $theme-vip-active-color !important;
      color: #fff !important;
    }
    &:disabled {
      background-color: $theme-vip-active-color !important;
      color: #fff !important;
    }
  }
  .btn-outline-primary {
    background: 0 0 !important;
    color: $theme-vip-bar !important;
    border: 3px solid $theme-vip-active-color !important;
  }

  .headerText {
    color: $theme-black;
  }

  .timelineLabelColor strong {
    color: $theme-vip-active-color !important;
  }
  .top-sidebar {
    .horizontal-menu {
      li {
        &.active {
          > a {
            color: $theme-vip-active-color;
          }
        }
        a {
          -moz-transition: all 0.3s;
          -o-transition: all 0.3s;
          -webkit-transition: all 0.3s;
          transition: all 0.3s;
          &:hover {
            color: $theme-vip-active-color;
          }
        }
      }
    }
  }
  .demo-choose-skin li.actived:after {
    color: #000;
  }

  .nav-tabs > li > a:before {
    border-bottom: 2px solid $theme-vip-active-color;
  }
  .search-box input#search {
    color: gray;
    background: #edecec;
  }

  .navbar-header {
    background-color: $theme-vip;
    .theme-text {
      color: $theme-vip-bar !important;
    }
  }
}
