.editor-container {
  .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-floating-label {
    top: 16px;
  }
  .mat-mdc-form-field .mdc-text-field--outlined .mdc-notched-outline--upgraded .mdc-floating-label--float-above {
    top: 28px;
    .NgxEditor__Wrapper {
      margin-top: 10px !important;
    }
  }
  .mat-mdc-form-field-infix {
    .ck-editor {
      margin-top: 16px;
    }
  }
}
.NgxEditor__Wrapper {
  margin-top: 20px;
}
.NgxEditor__Content {
  min-height: 80px !important;
}
