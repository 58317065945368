.profile-image-initial {
      display: inline-block;
      width: 100%;
      height: 15em;
      margin: auto;
      .fw-bold {
            font-weight: 500 !important;
      }
}
.profile-image-initial-sm {
      display: inline-block;
      width: 100%;
      height: 10em;
      margin: auto;
      .fw-bold {
            font-weight: 500 !important;
      }
}
.profile-image {
      width: auto;
      max-height: 400px;
      object-fit: contain !important;
}
.profile-image-sm {
      width: auto;
      max-height: 300px;
      object-fit: contain !important;
}
.profile-image-xs {
      width: auto;
      max-height: 200px;
      object-fit: contain !important;
}
.profile-image-xxs {
      width: 7em;
      max-height: 160px;
      object-fit: contain !important;
}
.profile-title-css {
      font-size: 23px;
      font-weight: 500;
}
.fa-2x.mat-icon {
      font-size: 30px !important;
      height: 30px;
      width: 30px !important;
}

div.divider {
      width: 100%;
      border-bottom: 1px solid #dee2e6 !important;
}
.fa-2x {
      font-size: 1.6em !important;
}
.profile-tab {
      .mdc-tab__text-label {
            font-size: 20px !important;
            font-weight: 500 !important;
            letter-spacing: 0.0178571429em !important;
      }
}
.address-css {
      font-size: 13px;
}
.mat-mdc-form-field-icon-suffix {
      padding: 0px 6px 0 6px !important;
}
.mat-button-toggle {
      color: #525965;
}

.switch {
      position: relative;
      display: inline-block;
      width: 53px;
      height: 26px;
      input {
            opacity: 0;
            width: 0;
            height: 0;
      }
      .slider {
            position: absolute;
            cursor: pointer;
            top: 0;
            left: 2px;
            right: 0;
            bottom: 0;
            background-color: #ccc;
            transition: 0.4s;
      }
      .slider:before {
            position: absolute;
            content: "";
            height: 22px;
            width: 22px;
            left: 2px;
            bottom: 2px;
            background-color: white;
            transition: 0.4s;
      }
      input:checked + .slider:before {
            -webkit-transform: translateX(26px);
            -ms-transform: translateX(26px);
            transform: translateX(26px);
      }
      .slider.round {
            border-radius: 34px;
      }
      .slider.round:before {
            border-radius: 50%;
      }
      label,
      .form-check {
            font-size: 17px !important;
      }
}
.fs-4 {
      label {
            font-size: 15px !important;
      }
      .mat-mdc-checkbox-inner-container {
            height: 20px !important;
            width: 20px !important;
      }
}
.mat-mdc-dialog-container .mdc-dialog__surface {
      display: block;
      width: 100%;
      height: 100%;
}
.mat-mdc-menu-panel {
      min-width: 176px !important;
      max-width: 100% !important;
}
.has-image-suffix .mat-mdc-form-field-suffix {
      top: -2px !important;
      .mat-icon {
            width: 45px !important;
            height: 40px !important;
      }
}
.mat-mdc-form-field-icon-prefix,
[dir="rtl"] .mat-mdc-form-field-icon-suffix {
      padding: 0 4px 0 8px !important;
}

::-webkit-scrollbar {
      width: 10px;
      height: 7px;
}
::-webkit-scrollbar-button {
      width: 0px;
      height: 0px;
}
::-webkit-scrollbar-thumb {
      background: #6a6a69;
      border: 0px none #ffffff;
      border-radius: 50px;
}
::-webkit-scrollbar-thumb:hover {
      background: #4a80de;
}
::-webkit-scrollbar-thumb:active {
      background: #525965;
}
::-webkit-scrollbar-track {
      background: transparent;
      border: 0px none #ffffff;
      border-radius: 50px;
}
::-webkit-scrollbar-track:hover {
      background: transparent;
}
::-webkit-scrollbar-track:active {
      background: transparent;
}
::-webkit-scrollbar-corner {
      background: transparent;
}
.d-card {
      min-height: 500px;
}
.btn-edit {
      border-radius: 50% !important;
      padding: auto;
}
.mat-mdc-dialog-surface,
.mdc-dialog__surface {
      padding: 24px;
}

.cdk-overlay-pane {
      max-width: 100% !important;
}
.cell-input {
      max-height: 60px !important;
}
tr {
      border-color: rgb(249, 249, 249) !important;
}
.mat-mdc-table .mat-mdc-header-cell,
.mat-mdc-cell,
.mat-mdc-footer-cell {
      font-size: 14px;
      white-space: unset !important;
      flex: 0 0 20% !important;
      padding-right: 10px !important;
      -ms-hyphens: auto;
      -moz-hyphens: auto;
      -webkit-hyphens: auto;
      hyphens: auto;
}
th.mat-mdc-header-cell,
td.mat-mdc-cell,
td.mat-mdc-footer-cell {
      padding-left: 10px !important;
}
.btn:disabled {
      opacity: 0.5 !important;
}
div.block-ui-wrapper {
      background-color: rgba(184, 204, 231, 0.308);
}
.d-chart-card {
      height: 500px;
}
.sidebar .menu .list i.fa {
      text-align: center;
      min-width: 20px;
}
.sidebar .menu .list i.fas {
      text-align: center;
      min-width: 20px;
}
.i-b {
      display: inline-block;
}
