.snackbar-success .mdc-snackbar__surface {
  background-color: rgba(58, 160, 52, 0.9) !important;
  color: #ffffff;
}

.snackbar-info .mdc-snackbar__surface{
  background-color: rgba(44, 167, 255, 0.9);
  color: #ffffff;
}

.snackbar-warning .mdc-snackbar__surface{
  background-color: rgba(255, 230, 0, 0.9);
  color: #ffffff;
}

.snackbar-danger .mdc-snackbar__surface{
  background-color: rgba(255, 0, 0, 0.9) !important;
  color: #ffffff;
}
