
//						[11. RIGHT SIDEBAR ]
						

.right-sidebar {
  .nav-tabs + .tab-content {
    padding: 0;
  }

  p {
    margin: 20px 15px 15px 15px;
    font-weight: bold;
    text-align: center;
  }

  #settings {
    .setting-list {
      list-style: none;
      padding-left: 0;
      margin-bottom: 20px;

      li {
        padding: 15px;
        position: relative;
        border-top: 1px solid #eee;

        .switch {
          position: absolute;
          top: 15px;
          right: 5px;
        }
      }
    }
  }
  .progress {
    -webkit-border-radius: 50px;
    -moz-border-radius: 50px;
    -ms-border-radius: 50px;
    border-radius: 50px;
    height: 7px;
    margin: 10px 0px 0px 0px;
  }
}
.right-sidebar .choose-theme li {
  position: relative;
  cursor: pointer;
  display: inline-block;
}

.demo-choose-skin,
.demo-choose-logoheader {
  list-style: none;
  padding-left: 0;
  overflow-y: hidden;

  li {
    padding: 10px 7px 4px 0px;
    position: relative;
    cursor: pointer;
    float: left;

    div {
      width: 24px;
      height: 24px;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      @include border-radius(3px);
    }

    span {
      position: relative;
      bottom: 7px;
      left: 5px;
    }
  }

  .white-theme {
    background-color: #fff;
  }

  .black-theme {
    background-color: #3a3f51;
  }

  .blue-theme {
    background-color: #03a9f3;
  }
  .vip-theme{
    background-color: #0070b7;
  }

  @each $key, $val in $colors {
    .#{$key} {
      background-color: $val;
    }
  }
}
.white-theme-border {
  border: 1px solid #888888;
}
.rightSetting {
  padding: 20px 25px 0px 25px;
}
.rightSetting p {
  font-weight: bold;
  margin: 0;
  border-bottom: 1px solid #eee;
  font-size: 12px;
  text-align: left;
}
.btn-sidebar-light,
.btn-theme-light {
  background: 0 0 !important;
  color: #888888 !important;
  border: 1px solid #888888 !important;
  margin: 10px;
}
//=====================================================================================
