@use "@angular/material" as mat;
@include mat.core();

@import "pallete.scss";

$light-primary: mat.define-palette(mat.$indigo-palette, 500);
$light-accent: mat.define-palette(mat.$pink-palette, A200, A100, A400);

$light-theme: mat.define-light-theme((
 color: (
   primary: $light-primary,
   accent: $light-accent,
 ),
 typography: mat.define-typography-config(
    $font-family: "Open Sans",
  )
));

@include mat.all-component-themes($light-theme);



.theme-white {
      $white-primary: mat.define-palette($custom-whiteprimary, 500);
      $white-accent: mat.define-palette($custom-whiteaccent, A200, A100, A400);
      $white-theme: mat.define-light-theme(
            (
                  color: (
                        primary: $white-primary,
                        accent: $white-accent
                  )
            )
      );

      @include mat.all-component-colors($white-theme);
}

.theme-black {
      $black-primary: mat.define-palette($custom-blackprimary, 500);
      $black-accent: mat.define-palette($custom-blackaccent, A200, A100, A400);
      $black-theme: mat.define-light-theme(
            (
                  color: (
                        primary: $black-primary,
                        accent: $black-accent
                  )
            )
      );

      @include mat.all-component-colors($black-theme);
}

.theme-vip {
      $vip-primary: mat.define-palette($custom-vipprimary, 500);
      $vip-accent: mat.define-palette($custom-vipaccent, A200, A100, A400);
      $vip-theme: mat.define-light-theme(
            (
                  color: (
                        primary: $vip-primary,
                        accent: $vip-accent
                  )
            )
      );

      @include mat.all-component-colors($vip-theme);
      .text-danger {
            color: #bf2026 !important;
      }
}
