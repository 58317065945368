/*
 *  Document   : _variables.scss
 *  Author     : RedStar Template
 *  Description: This scss file for declare variable for colors
 */
$white: #fff;
$color_orange: #ffa442;
$color_blue: #4b64ff;
$color_red: #ff4b4b;
$color_green: #40c952;
$colors: (
  "red": #b91a0e,
  "pink": #e91e63,
  "purple": #9c27b0,
  "indigo": #3f51b5,
  "blue": #2196f3,
  "cyan": #00bcd4,
  "teal": #009688,
  "green": #28c76f,
  "yellow": #ffe821,
  "orange": #ff9800,
  "deep-orange": #ff5722,
  "brown": #795548,
  "grey": #9e9e9e,
  "black": #000000,
  "white": #ffffff
) !default;

$linear-colors: (
  "green": linear-gradient(45deg, #9ce89d, #cdfa7e),
  "orange": linear-gradient(135deg, #ffc480, #ff763b),
  "cyan": linear-gradient(45deg, #72c2ff, #86f0ff),
  "purple": linear-gradient(230deg, #759bff, #843cf6),
  "purple-dark": linear-gradient(45deg, #a52dd8, #e29bf1),
  "red": linear-gradient(45deg, #bd3333, #a53b3b),
  "card1": linear-gradient(to left, #3a7bd5, #3a6073),
  "card2": linear-gradient(to right, #c33764, #1d2671),
  "card3": linear-gradient(to left, #134e5e, #71b280),
  "card4": linear-gradient(to left, #d38312, #a83279)
);

//Fonts Family
$navbar-font-family: "Open Sans";
$sidebar-font-family: "Open Sans";
