$custom-whiteprimary: (
    50 : #e0f7fa,
    100 : #b3ebf2,
    200 : #80deea,
    300 : #4dd0e1,
    400 : #26c6da,
    500 : #00bcd4,
    600 : #00b6cf,
    700 : #00adc9,
    800 : #00a5c3,
    900 : #0097b9,
    A100 : #e2f9ff,
    A200 : #afeeff,
    A400 : #7ce3ff,
    A700 : #63ddff,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #000000,
        400 : #000000,
        500 : #000000,
        600 : #000000,
        700 : #ffffff,
        800 : #ffffff,
        900 : #ffffff,
        A100 : #000000,
        A200 : #000000,
        A400 : #000000,
        A700 : #000000,
    )
);

$custom-whiteaccent: (
    50 : #e8e8e8,
    100 : #c6c5c6,
    200 : #a09ea0,
    300 : #797779,
    400 : #5d595d,
    500 : #403c40,
    600 : #3a363a,
    700 : #322e32,
    800 : #2a272a,
    900 : #1c1a1c,
    A100 : #f570f5,
    A200 : #f141f1,
    A400 : #ff00ff,
    A700 : #e600e5,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #ffffff,
        400 : #ffffff,
        500 : #ffffff,
        600 : #ffffff,
        700 : #ffffff,
        800 : #ffffff,
        900 : #ffffff,
        A100 : #000000,
        A200 : #000000,
        A400 : #ffffff,
        A700 : #ffffff,
    )
);

$custom-warn: (
    50 : #f7e4e5,
    100 : #ecbcbe,
    200 : #df9093,
    300 : #d26367,
    400 : #c94147,
    500 : #bf2026,
    600 : #b91c22,
    700 : #b1181c,
    800 : #a91317,
    900 : #9b0b0d,
    A100 : #ffc9ca,
    A200 : #ff9697,
    A400 : #ff6365,
    A700 : #ff4a4b,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #000000,
        400 : #ffffff,
        500 : #ffffff,
        600 : #ffffff,
        700 : #ffffff,
        800 : #ffffff,
        900 : #ffffff,
        A100 : #000000,
        A200 : #000000,
        A400 : #000000,
        A700 : #000000,
    )
);

$custom-blackprimary: (
    50 : #e0e0e0,
    100 : #b3b3b3,
    200 : #808080,
    300 : #4d4d4d,
    400 : #262626,
    500 : #000000,
    600 : #000000,
    700 : #000000,
    800 : #000000,
    900 : #000000,
    A100 : #a6a6a6,
    A200 : #8c8c8c,
    A400 : #737373,
    A700 : #666666,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #ffffff,
        400 : #ffffff,
        500 : #ffffff,
        600 : #ffffff,
        700 : #ffffff,
        800 : #ffffff,
        900 : #ffffff,
        A100 : #000000,
        A200 : #000000,
        A400 : #ffffff,
        A700 : #ffffff,
    )
);

$custom-blackaccent: (
    50 : #e4e3ea,
    100 : #bcb8ca,
    200 : #9089a6,
    300 : #635982,
    400 : #413668,
    500 : #20124d,
    600 : #1c1046,
    700 : #180d3d,
    800 : #130a34,
    900 : #0b0525,
    A100 : #7561ff,
    A200 : #492eff,
    A400 : #2000fa,
    A700 : #1d00e1,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #ffffff,
        400 : #ffffff,
        500 : #ffffff,
        600 : #ffffff,
        700 : #ffffff,
        800 : #ffffff,
        900 : #ffffff,
        A100 : #ffffff,
        A200 : #ffffff,
        A400 : #ffffff,
        A700 : #ffffff,
    )
);

/* For use in src/lib/core/theming/_palette.scss */
$custom-vipprimary: (
    50 : #e0eef6,
    100 : #b3d4e9,
    200 : #80b8db,
    300 : #4d9bcd,
    400 : #2685c2,
    500 : #0070b7,
    600 : #0068b0,
    700 : #005da7,
    800 : #00539f,
    900 : #004190,
    A100 : #bcd5ff,
    A200 : #89b5ff,
    A400 : #5695ff,
    A700 : #3c85ff,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #000000,
        400 : #ffffff,
        500 : #ffffff,
        600 : #ffffff,
        700 : #ffffff,
        800 : #ffffff,
        900 : #ffffff,
        A100 : #000000,
        A200 : #000000,
        A400 : #000000,
        A700 : #ffffff,
    )
);




/* For use in src/lib/core/theming/_palette.scss */
$custom-vipaccent: (
    50 : #e0e5e9,
    100 : #b3bfc9,
    200 : #8095a5,
    300 : #4d6a81,
    400 : #264a66,
    500 : #002a4b,
    600 : #002544,
    700 : #001f3b,
    800 : #001933,
    900 : #000f23,
    A100 : #5f8bff,
    A200 : #2c66ff,
    A400 : #0044f8,
    A700 : #003ddf,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #ffffff,
        400 : #ffffff,
        500 : #ffffff,
        600 : #ffffff,
        700 : #ffffff,
        800 : #ffffff,
        900 : #ffffff,
        A100 : #000000,
        A200 : #ffffff,
        A400 : #ffffff,
        A700 : #ffffff,
    )
);

