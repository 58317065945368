/*
 *  Document   : _leftsidebaroverlay.scss
 *  Author     : RedStar Template
 *  Description: This scss file for left side bar style classes
 */
@import "../theme/_theme-color-variables";
.overlay {
  position: fixed;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 10;
}

.overlay-open {
  .sidebar {
    margin-left: 0;
    z-index: 99999999;
  }
}

.sidebar {
  @include transition(all 0.5s);
  font-family: $sidebar-font-family;
  background: #ffffff;
  width: 268px;
  overflow: hidden;
  display: inline-block;
  height: calc(100vh);
  position: fixed;
  top: 0;
  left: 0;
  //  @include box-shadow(2px 2px 5px rgba(0, 0, 0, 0.1));
  z-index: 999 !important;

  .user-info {
    padding: 13px 15px 12px 15px;
    white-space: nowrap;
    position: relative;
    border-bottom: 1px solid #e9e9e9;
    height: 135px;

    .image {
      margin-right: 12px;
      display: inline-block;

      img {
        @include border-radius(50%);
        vertical-align: bottom !important;
      }
    }

    .info-container {
      cursor: default;
      display: block;
      position: relative;
      top: 25px;

      .name {
        @include three-dots-overflow();
        font-size: 14px;
        max-width: 200px;
        color: #fff;
      }

      .email {
        @include three-dots-overflow();
        font-size: 12px;
        max-width: 200px;
        color: #fff;
      }

      .user-helper-dropdown {
        position: absolute;
        right: -3px;
        bottom: -12px;
        @include box-shadow(none);
        cursor: pointer;
        color: #fff;
      }
    }
  }

  .menu {
    position: relative;
    overflow-y: auto;
    height: 100vh;

    .list {
      list-style: none;
      padding-left: 0;

      li {
        &.active {
          .menu-top {
            background-color: #f0f3fb;
          }
          > :first-child {
            span {
              font-weight: bold;
            }
          }
        }
      }

      .header {
        font-size: 12px;
        font-weight: 600;
        padding: 15px 20px 5px;
        color: #828282;
      }

      i.material-icons {
        font-size: 15px;
      }

      i.fas,.material-symbols-outlined {
        font-size: 15px;
      }

      i.far {
        font-size: 15px;
      }

      i.fab {
        font-size: 15px;
      }

      i.fa {
        font-size: 15px;
      }

      .tooltips .sidebarQuickIcon {
        font-size: 18px;
        margin-top: 10px;
      }

      .active {
        .menu-toggle {
          background-color: #f0f3fb;
        }

        .ml-menu {
          display: block;
        }
      }

      .menu-toggle {
        &:after,
        &:before {
          position: absolute;
          //top: calc(50% - 11px);
          right: 17px;
          font-size: 19px;
          @include transform(scale(0));
          @include transition(all 0.3s);
        }

        &:before {
          content: "+";
          @include transform(scale(1));
        }

        &:after {
          content: "\2013";
          @include transform(scale(0));
        }
      }

      .active .menu-toggle {
        &:before {
          @include transform(scale(0));
        }

        &:after {
          @include transform(scale(1));
        }
      }

      .ml-sub-menu {
        &:after,
        &:before {
          position: absolute;
          top: calc(50% - 11px);
          right: 17px;
          font-size: 19px;
          @include transform(scale(0));
          @include transition(all 0.3s);
        }

        &:before {
          content: "+";
          @include transform(scale(1));
        }

        &:after {
          content: "\2013";
          @include transform(scale(0));
        }
      }

      .activeSub .ml-sub-menu {
        &:before {
          @include transform(scale(0));
        }

        &:after {
          @include transform(scale(1));
        }
      }

      a {
        color: #000000;
        position: relative;
        //vertical-align: middle;
        font-size: 14px;
        display: block;
        overflow: hidden;
        line-height: 2rem;
        padding: 9px 9px 9px 18px;
        margin: 8px 13px 0px 13px;
        border-radius: 3rem;

        &:hover,
        &:active,
        &:focus {
          text-decoration: none !important;
          background-color: #f0f3fb !important;
        }

        small {
          position: absolute;
          top: calc(50% - 7.5px);
          right: 15px;
        }

        span {
          margin: 7px 0 7px 7px;
          color: #333;
          // font-weight: bold;
          font-size: 14px;
          overflow: hidden;
        }
      }

      .ml-menu {
        list-style: none;
        display: none;
        padding-left: 0;

        span {
          font-weight: normal;
          font-size: 14px;
          margin: 3px 0 1px 6px;
        }

        li {
          a {
            padding-left: 48px;
            padding-top: 4px;
            padding-bottom: 4px;
          }

          &.active {
            a.toggled:not(.menu-toggle) {
              &:before {
                content: "";
                display: block;
                width: 7px;
                height: 7px;
                border-radius: 50%;
                position: absolute;
                left: 16%;
                top: 50%;
                -webkit-transform: translate(-10px, -50%);
                -moz-transform: translate(-10px, -50%);
                -ms-transform: translate(-10px, -50%);
                -o-transform: translate(-10px, -50%);
                transform: translate(-10px, -50%);
                opacity: 0.5;
                -webkit-transition: all 0.2s ease;
                -o-transition: all 0.2s ease;
                -moz-transition: all 0.2s ease;
                transition: all 0.2s ease;
              }
            }

            a {
              &:before {
                content: "\f068";
                font-family: "Font Awesome 5 Free";
                font-size: 11px;
                display: block;
                width: 7px;
                height: 7px;
                position: absolute;
                left: 14%;
                top: 50%;
                font-weight: 900;
              }
            }
            .ml-menu-2 {
              display: block;
            }
          }

          .ml-menu {
            li {
              a {
                padding-left: 80px;
              }
            }

            .ml-menu {
              li {
                a {
                  padding-left: 95px;
                }
              }
            }
          }
        }

        .activeSub {
          .ml-menu-2 {
            display: block;
          }
        }
      }

      .ml-menu-2 {
        list-style: none;
        display: none;
        padding-left: 0;

        span {
          font-weight: normal;
          font-size: 14px;
          margin: 3px 0 1px 6px;
        }

        li {
          a {
            padding-left: 65px;
            padding-top: 4px;
            padding-bottom: 4px;
          }
        }
      }
    }
  }
}

.right-sidebar {
  width: 280px;
  height: calc(100vh - 60px);
  position: fixed;
  right: -300px;
  top: 60px;
  background: #fdfdfd;
  z-index: 999 !important;
  @include box-shadow(-2px 2px 5px rgba(0, 0, 0, 0.1));
  overflow: hidden;
  @include transition(0.5s);

  &.open {
    right: 0;
  }

  .nav-tabs {
    font-weight: 600;
    font-size: 13px;
    width: 100%;
    margin-left: 2px;

    li {
      text-align: center;

      > a {
        margin-right: 0;
      }

      &:first-child {
        width: 45%;
      }

      &:last-child {
        width: 55%;
      }
    }
  }

  .rightSidebarClose {
    position: absolute;
    top: 0px;
    font-size: 17px;
    left: 3px;
    cursor: pointer;
    z-index: 99;
  }
}

.boxed-layout {
  .sidebar {
    left: auto;
  }

  &.side-closed .sidebar {
    left: 0;

  }
}

 .side-closed .sidebar .advertisements{
display:none !important;
}

.horizontal-menu {
  width: 100%;
  text-align: center;
}

.top-sidebar {
  float: left;
  width: 100%;
  position: fixed;
  z-index: 9;
  top: 60px;
  background-color: #ffffff;
  -webkit-box-shadow: 0 1px 5px rgba(0, 0, 0, 0.3);
  -moz-box-shadow: 0 1px 5px rgba(0, 0, 0, 0.3);
  -ms-box-shadow: 0 1px 5px rgba(0, 0, 0, 0.3);
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.3);

  ul.horizontal-list {
    overflow: visible !important;
    margin: 0;
    display: inline-block;
    height: 60px;

    a {
      color: #747474;
      float: left;
      width: 100%;
    }

    li {
      float: left;
      position: relative;
      padding: 20px 20px;

      &:hover > ul {
        display: block !important;
        position: absolute;
        top: 63px;
        background-color: #fff;
        border-radius: 5px;
        z-index: 9;
        -webkit-box-shadow: 0 1px 5px rgba(0, 0, 0, 0.3);
        -moz-box-shadow: 0 1px 5px rgba(0, 0, 0, 0.3);
        -ms-box-shadow: 0 1px 5px rgba(0, 0, 0, 0.3);
        box-shadow: 0 1px 5px rgba(0, 0, 0, 0.3);

        &:after {
          position: absolute;
          bottom: 100%;
          left: 1.8rem;
          width: 0;
          height: 0;
          margin-left: -16px;
          content: " ";
          pointer-events: none;
          border: solid transparent;
          border-width: 9px;
          border-color: rgba(136, 183, 213, 0);
          border-bottom-color: #fff;
        }

        &:before {
          position: absolute;
          bottom: 100%;
          left: 1.8rem;
          width: 0;
          height: 0;
          margin-left: -16px;
          content: " ";
          pointer-events: none;
          border: solid transparent;
          border-width: 9px;
          border-color: rgba(136, 183, 213, 0);
          border-bottom-color: #ad5454;
        }

        &.mega-ml-menu {
          position: relative;
          top: 0;
        }
      }

      ul li {
        width: 100%;
        padding: 10px 15px;
        text-align: left;
      }
    }

    ul.ml-menu {
      display: none !important;
      width: 200px;

      li:hover ul {
        top: 0;
        left: 100%;
      }

      .menu-toggle {
        position: relative;

        &:after {
          content: "\2023";
          position: absolute;
          right: 0;
          font-size: 25px;
          top: 0;
          line-height: 22px;
        }
      }
    }

    i.material-icons {
      margin-top: 1px;
      margin-right: 3px;
      float: left;
      font-size: 20px;
    }

    // .menu-toggle::after{
    //     content: "\2304";
    //     position: absolute;
    //     top: 15px;
    //     right: 0;
    // }
  }

  .slimScrollDiv {
    overflow: visible !important;
  }
}

// Dark left sidebar style
.menu_dark .sidebar {
  background: $theme-black;
  font-weight: 500;

  .menu .list {
    a {
      color: #e6e6e6;

      span {
        color: #e6e6e6;
      }
    }

    a:hover {
      background-color: rgba(0, 0, 0, 0.2);
    }

    .header {
      font-size: 12px;
      font-weight: 600;
      padding: 15px 20px 5px;
      background: transparent;
      color: rgba(255, 255, 255, 0.7);
    }

    .active {
      .menu-toggle {
        background-color: #131517;
      }
    }
    li {
      &.active {
        .menu-top {
          background-color: rgba(0, 0, 0, 0.4);
        }
      }
    }
  }

  .menu .list .ml-menu .active a::before {
    background-color: #fff !important;
    border-color: #fff !important;
  }
}

.menu_dark .sidebar-userpic-name {
  color: #e6e6e6;
}

.user-panel {
  float: left;
  width: 100%;
  color: #ccc;
  padding: 25px 10px 10px 0;
  border-bottom: 2px solid rgba(174, 174, 174, 0.342);
  .image {
    width: 50%;
    max-width: 90px;
    margin: 0 auto;

    img {
      max-width: 100%;
    }
  }
}

.user-img-circle {
  background: #fff;
  z-index: 1000;
  position: inherit;
  border: 1px solid rgba(52, 73, 94, 0.44);
  padding: 2px;
  box-shadow: 0 5px 25px 0 rgba(0, 0, 0, 0.2);
}

.img-circle {
  border-radius: 50%;
}

.profile-usertitle {
  text-align: center;
  color: #6f6f6f;
}

.profile-usertitle-job {
  font-size: 10px;
  color: #919aa3;
}

.sidebar-userpic-btn {
  display: flex;
  place-content: space-around;
  font-size: 18px;
  width: 190px;
  text-align: center;
  margin: auto;

  a {
    color: #aaadb7;

    &:hover {
      background-color: transparent !important;
    }
  }
}

.collapse.in {
  display: block;
}

// // Red left sidebar style
// .menu_red .mat-mdc-mini-fab.mat-primary {
//   background-color: $theme-red !important;
// }
// .menu_red .bgColor {
//   background-color: $theme-red !important;
// }
// .menu_red .sidebar {
//   background: $theme-red !important;
//   font-weight: 500;

//   .menu .list {
//     a {
//       color: #e6e6e6;

//       span {
//         color: #e6e6e6;
//       }
//     }

//     a:hover {
//       background-color: rgba(0, 0, 0, 0.2);
//     }

//     .header {
//       font-size: 12px;
//       font-weight: 600;
//       padding: 15px 20px 5px;
//       background: transparent;
//       color: rgba(255, 255, 255, 0.7);
//     }

//     .active {
//       .menu-toggle {
//         background-color: #131517;
//       }
//     }

//     li {
//       &.active {
//         .menu-top {
//           background-color: rgba(0, 0, 0, 0.4);
//         }
//       }
//     }
//   }

//   .menu .list .ml-menu .active a::before {
//     background-color: #fff !important;
//     border-color: #fff !important;
//   }
// }

// .menu_red .sidebar-userpic-name {
//   color: #e6e6e6;
// }

// // orange left sidebar style
// .menu_orange .mat-mdc-mini-fab.mat-primary {
//   background-color: $theme-orange !important;
// }
// .menu_orange .bgColor {
//   background-color: $theme-orange !important;
// }
// .menu_orange .sidebar {
//   background: $theme-orange !important;
//   font-weight: 500;

//   .menu .list {
//     a {
//       color: #e6e6e6;

//       span {
//         color: #ffffff;
//       }
//     }

//     a:hover {
//       background-color: rgba(0, 0, 0, 0.2);
//     }

//     .header {
//       font-size: 12px;
//       font-weight: 600;
//       padding: 15px 20px 5px;
//       background: transparent;
//       color: rgba(255, 255, 255, 0.7);
//     }

//     .active {
//       .menu-toggle {
//         background-color: #131517;
//       }
//     }

//     li {
//       &.active {
//         .menu-top {
//           background-color: rgba(0, 0, 0, 0.4);
//         }
//       }
//     }
//   }

//   .menu .list .ml-menu .active a::before {
//     background-color: #fff !important;
//     border-color: #fff !important;
//   }
// }

// .menu_orange .sidebar-userpic-name {
//   color: #e6e6e6;
// }

// // green left sidebar style
// .menu_green .mat-mdc-mini-fab.mat-primary {
//   background-color: $theme-green !important;
// }
// .menu_green .bgColor {
//   background-color: $theme-green !important;
// }
// .menu_green .sidebar {
//   background: $theme-green !important;
//   font-weight: 500;

//   .menu .list {
//     a {
//       color: #ffffff;

//       span {
//         color: #ffffff;
//       }
//     }

//     a:hover {
//       background-color: rgba(0, 0, 0, 0.2);
//     }

//     .header {
//       font-size: 12px;
//       font-weight: 600;
//       padding: 15px 20px 5px;
//       background: transparent;
//       color: rgba(255, 255, 255, 0.7);
//     }

//     .active {
//       .menu-toggle {
//         background-color: #131517;
//       }
//     }

//     li {
//       &.active {
//         .menu-top {
//           background-color: rgba(0, 0, 0, 0.4);
//         }
//       }
//     }
//   }

//   .menu .list .ml-menu .active a::before {
//     background-color: #fff !important;
//     border-color: #fff !important;
//   }
// }

// .menu_green .sidebar-userpic-name {
//   color: #e6e6e6;
// }

// // cyan left sidebar style

// .menu_cyan .sidebar {
//   background: $theme-cyan;
//   font-weight: 500;

//   .menu .list {
//     a {
//       color: #2c303b;

//       span {
//         color: #2c303b;
//       }
//     }

//     a:hover {
//       background-color: rgba(0, 0, 0, 0.2);
//     }

//     .header {
//       font-size: 12px;
//       font-weight: 600;
//       padding: 15px 20px 5px;
//       background: transparent;
//       color: rgba(255, 255, 255, 0.84);
//     }

//     .active {
//       .menu-toggle {
//         background-color: #131517;
//       }
//     }

//     li {
//       &.active {
//         .menu-top {
//           background-color: rgba(0, 0, 0, 0.4);
//         }
//       }
//     }
//   }

//   .menu .list .ml-menu .active a::before {
//     background-color: #fff !important;
//     border-color: #fff !important;
//   }
// }
// .menu_cyan .mat-mdc-mini-fab.mat-primary {
//   background-color: $theme-cyan !important;
// }
// .menu_cyan .bgColor {
//   background-color: $theme-cyan !important;
// }
// .menu_cyan .sidebar-userpic-name {
//   color: #e6e6e6;
// }

// blue left sidebar style
// .menu_blue .mat-mdc-mini-fab.mat-primary {
//   background-color: $theme-blue !important;
// }
.menu_blue .bgColor {
  background-color: $theme-blue !important;
}
.menu_blue .sidebar {
  background: $theme-blue !important;
  font-weight: 500;

  .menu .list {
    a {
      color: #e6e6e6;

      span {
        color: #e6e6e6;
      }
    }

    a:hover {
      background-color: rgba(0, 0, 0, 0.2);
    }

    .header {
      font-size: 12px;
      font-weight: 600;
      padding: 15px 20px 5px;
      background: transparent;
      color: rgba(255, 255, 255, 0.849);
    }

    .active {
      .menu-toggle {
        background-color: #131517;
      }
    }

    li {
      &.active {
        .menu-top {
          background-color: rgba(0, 0, 0, 0.4);
        }
      }
    }
  }

  .menu .list .ml-menu .active a::before {
    background-color: #fff !important;
    border-color: #fff !important;
  }
}

.menu_blue .sidebar-userpic-name {
  color: #e6e6e6;
}

// // purple left sidebar style
// .menu_purple .mat-mdc-mini-fab.mat-primary {
//   background-color: $theme-purple !important;
// }
// .menu_purple .bgColor {
//   background-color: $theme-purple !important;
// }
// .menu_purple .sidebar {
//   background: $theme-purple !important;
//   font-weight: 500;

//   .menu .list {
//     a {
//       color: #e6e6e6;

//       span {
//         color: #e6e6e6;
//       }
//     }

//     a:hover {
//       background-color: rgba(0, 0, 0, 0.2);
//     }

//     .header {
//       font-size: 12px;
//       font-weight: 600;
//       padding: 15px 20px 5px;
//       background: transparent;
//       color: rgba(255, 255, 255, 0.813);
//     }

//     .active {
//       .menu-toggle {
//         background-color: #131517;
//       }
//     }

//     li {
//       &.active {
//         .menu-top {
//           background-color: rgba(0, 0, 0, 0.4);
//         }
//       }
//     }
//   }

//   .menu .list .ml-menu .active a::before {
//     background-color: #fff !important;
//     border-color: #fff !important;
//   }
// }

// .menu_purple .sidebar-userpic-name {
//   color: #e6e6e6;
// }

// black left sidebar style
// .menu_black .mat-mdc-mini-fab.mat-primary {
//   background-color: #3a3f51 !important;
// }
.menu_black .bgColor {
  background-color: #3a3f51 !important;
}
.menu_black .sidebar {
  background: #1a202e;
  font-weight: 500;

  .menu .list {
    a {
      color: #e6e6e6;

      span {
        color: #e6e6e6;
      }
    }

    a:hover {
      background-color: rgba(0, 0, 0, 0.2);
    }

    .header {
      font-size: 12px;
      font-weight: 600;
      padding: 15px 20px 5px;
      background: transparent;
      color: rgba(255, 255, 255, 0.7);
    }

    .active {
      .menu-toggle {
        background-color: #131517;
      }
    }

    li {
      &.active {
        .menu-top {
          background-color: rgba(0, 0, 0, 0.4);
        }
      }
    }
  }

  .menu .list .ml-menu .active a::before {
    background-color: #fff !important;
    border-color: #fff !important;
  }
}

.menu_black .sidebar-userpic-name {
  color: #e6e6e6;
}

// white left sidebar style
// .menu_white .mat-mdc-mini-fab.mat-primary {
//   background-color: $theme-white !important;
//   color: #3a3f51 !important;
// }
.menu_black .bgColor {
  background-color: $theme-white !important;
}
.menu_white .sidebar {
  background: $theme-white;
  font-weight: 500;

  .menu .list {
    a {
      color: #2c303b;

      span {
        color: #2c303b;
      }
    }

    a:hover {
      background-color: rgba(0, 0, 0, 0.2);
    }

    .header {
      font-size: 12px;
      font-weight: 600;
      padding: 15px 20px 5px;
      background: transparent;
      color: rgba(38, 38, 38, 0.797);
    }

    .active {
      .menu-toggle {
        background-color: #131517;
      }
    }

    li {
      &.active {
        .menu-top {
          background-color: rgba(0, 0, 0, 0.4);
        }
      }
    }
  }

  .menu .list .ml-menu .active a::before {
    background-color: #1e1e1e !important;
    border-color: #fff !important;
  }
}

.menu_white .sidebar-userpic-name {
  color: #525050;
}
