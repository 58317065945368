﻿/*
 *  Document   : _collapse.scss
 *  Author     : RedStar Template
 *  Description: This scss file for collapse style classes
 */
.collapse,
.collapse.in,
.collapsing {
  .well {
    @include border-radius(0);
    margin-bottom: 0;
  }
  &.show {
    display: block;
  }
}

tr.example-detail-row {
    height: 0;
}

tr.example-element-row:not(.example-expanded-row):hover {
    background: whitesmoke;
}

tr.example-element-row:not(.example-expanded-row):active {
    background: #efefef;
}

.example-element-row td {
    border-bottom-width: 0;
}

.example-element-detail {
    overflow: hidden;
    display: flex;
}

.example-element-diagram {
    display: flex;
    align-items: center;
    min-width: 100px;
    font-weight: lighter;
    height: 100px;
    word-wrap: break-word !important;
    white-space: unset !important;
    overflow-wrap: break-word;
    word-wrap: break-word;
    word-break: break-word;
    -ms-hyphens: auto;
    -moz-hyphens: auto;
    -webkit-hyphens: auto;
    hyphens: auto;
}

.example-element-symbol {
    font-weight: bold;
    font-size: 40px;
    line-height: normal;
}



.example-element-description-attribution {
    opacity: 0.5;
}
