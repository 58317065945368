
@import "../../../node_modules/bootstrap/scss/bootstrap";
@import "common/_variables";
@import "common/_mixins";
@import "common/_general.scss";
@import "common/_demo.scss";
@import "common/_helpers.scss";
@import "common/_media.scss";

/*                 [2. Fonts]                   				*/
// @import "fonts/_material.scss";
@import "fonts/fontawesome/fontawesome.scss";
@import "fonts/fontawesome/regular.scss";
@import "fonts/fontawesome/solid.scss";
@import "fonts/fontawesome/brands.scss";

/*                 [3. Components ]                   		*/
@import "components/_breadcrumbs.scss";
@import "components/_checkboxradio.scss";
@import "components/_dropdownmenu.scss";
@import "components/_formcomponents.scss";
@import "components/_infobox.scss";
@import "components/_inputformgroup.scss";
@import "components/_leftsidebaroverlay.scss";
@import "components/_navbar";
@import "components/_navtabs.scss";
@import "components/_rightsidebar.scss";
@import "components/_searchbar.scss";
@import "components/_switch.scss";

/*                 [4. Apps]                   				*/
@import "apps/_calendar.scss";
/*                 [5. Pages ]                   				*/
@import "pages/_extra_pages.scss";
@import "pages/_auth.scss";

/*                 [6. Pugins ]                   				*/
@import "plugins/_tables.scss";

/*                 [7. UI ]                   */
@import "ui/_alerts.scss";
@import "ui/_card.scss";
@import "ui/_modals.scss";
@import "ui/_collapse.scss";
@import "ui/_dialogs.scss";
@import "ui/_snackbar.scss";
@import "ui/_ckeditor.scss";

/*                 [8. Browser ]                   			*/
@import "browser/_ie10.scss";
@import "browser/_ie11.scss";
@import "custom.scss";
@import "material.scss";