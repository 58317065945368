.init-loading {
      app-root:empty {
          --size: 7rem;
          --stroke-width: calc(var(--size) / 8);
          --color: transparent;
          --animation-duration: 1.5s;
          position: absolute;
          margin: 0;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          width: var(--size);
          height: var(--size);
      }
  
      app-root:empty::before {
          content: "";
          position: absolute;
          inset: 0;
          border-width: var(--stroke-width);
          border-style: solid;
          border-color: var(--color) var(--color) transparent transparent;
          border-radius: 50%;
          transform: rotate(0deg);
          animation: var(--animation-duration) infinite circle-spin;
      }
      app-root:empty::after{
          content: "Loading Marketplace";
          position: absolute;
          top: 100%;
          width: max-content;
          left: 50%;
          font-size: 20px;
          padding-top: 10px;
          font-weight: 500;
          color: var(--color);
          transform: translate(-50%, 0%);
      }
  
      app-root:empty::before {
          animation-timing-function: cubic-bezier(0.5, 0, 0.75, 0);
      }
  
      app-root:empty::before {
          animation-timing-function: cubic-bezier(0.25, 1, 0.5, 1);
      }
  
      @keyframes circle-spin {
          from {
              transform: rotate(0deg);
          }
  
          to {
              transform: rotate(360deg);
          }
      }
      
  }